import * as React from "react"
import { graphql } from 'gatsby';

import Accordion from "components/Accordion";
import AccordionItem from "components/AccordionItem";
import BetaCTA from 'components/BetaCTA'
import Button from 'components/RequestBetaButton'
import Container from 'components/Container'
import FeatureImage from 'components/FeatureImage'
import Section from 'components/Section'
import Label from 'components/Label'
import Layout from 'components/layout'
import Seo from 'components/seo'
import TextGroup from 'components/TextGroup'
import TitleGroup from 'components/TitleGroup'
import Text from 'components/Text'

import BrandkitGridIllustration from 'components/BrandkitGridIllustration';
import InteractiveModulesIllustration from 'components/InteractiveModulesIllustration';
import StackedPreviews from 'components/StackedPreviews';

const EnterprisePage = ({ data }) => (
  <Layout theme="dark">
    <Seo 
		title="Simplate for Teams" 
		metaDescription="Simplate allows teams to create video variations efficiently and effectively." 
	/>
    <Container marginTop={[10, 10, 12]}>
		<Section>
			<TitleGroup span={12}>
				<Text as="h2" textStyle="title.lg">
					Create hundreds of video variations, efficiently and effectively.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Videos created by your vendors become a simple template <br /> anyone in your organization can adjust.
				</Text>
				<Button size="lg">
					<Text>Start versioning for free</Text>
				</Button>
			</TitleGroup>
			<FeatureImage src={data.creatorImage} alt="Simplate Creator">
				<TextGroup align="center">
					<Text as="h4" textStyle="title.xs">
						No costly agency updates required
					</Text>
					<Text color="altText" textStyle="text.md">
						Once your professionally built videos become a simple template, called a Simplate, you can adjust, iterate and export without the agency's involvement.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
		<Section>
			<TitleGroup align="center">
				<Text as="h2" gradient="purple" textStyle="title.md">
					A centralized video versioning platform for teams.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Collaborate on templates across any sized organization directly in Simplate.
				</Text>
			</TitleGroup>
			<FeatureImage src={data.review} alt="Review">
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Real-time feedback
					</Text>
					<Text color="altText" textStyle="text.md">
						Streamline your review process by leaving trackable feedback for the team members creating the variations.
					</Text>
				</TextGroup>
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Globally shared assets
					</Text>
					<Text color="altText" textStyle="text.md">
						Universally share elements with collaboratos within Simplate's cloud-based asset library.
					</Text>
				</TextGroup>
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Flexible team structure
					</Text>
					<Text color="altText" textStyle="text.md">
						Administrators can organize by teams, projects or campaigns. It's up to you.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
		<Section>
			<TitleGroup span={9}>
				<Text as="h2" gradient="blue" textStyle="title.md">
					Designed for anyone, from marketing directors to interns.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Remove the complexity. No prior experience with complex video software required.
				</Text>
				<Button color="blue" size="lg">
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<Accordion reverse>
				<AccordionItem 
					component={<StackedPreviews images={data.stackedPreviews.nodes} />}
					text="You made it, now sell it! Share Simplates with your clients in your own private marketplace."
					title="Batch Adjust"
				/>
				<AccordionItem 
					component={<BrandkitGridIllustration />}
					text="Auto-populate every video with your brands, logos, fonts and colors."
					title="Auto-Population"
				/>
				<AccordionItem 
					component={<InteractiveModulesIllustration />}
					text="As easy as drag and drop. Swap images, logos, footage and audio to quickly version your videos."
					title="Interactive Modules"
				/>
			</Accordion>
		</Section>
		<Section>
			<TitleGroup>
				<Text as="h2" gradient="orange" textStyle="title.md">
					Give your marketing team powerful new abilities.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Automatic video generation, live data connections and more with these upcoming features.
				</Text>
				<Button color="orange" size="lg">
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<FeatureImage src={data.comingSoon} alt="Coming Soon">
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Connect data sources
					</Text>
					<Text color="altText" textStyle="text.md">
						Set up a connection to any data source to drive the content of your Simplate, no matter your expertise.
					</Text>
				</TextGroup>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Live data feeds
					</Text>
					<Text color="altText" textStyle="text.md">
						Automatically feed live data directly into your Simplates. Set event triggers and post near real-time topical videos.
					</Text>
				</TextGroup>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Integrate Social Tools
					</Text>
					<Text color="altText" textStyle="text.md">
						Combine Simplate's automation capabilities with your companies social media management tool for complete automation from population to posting.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
	</Container>
	<BetaCTA 
		src={data.creatorImage} 
	>
		<TitleGroup align="center" offset={2} span={10}>
			<Label color="text"><Text>Keep It Simple</Text></Label>
			<Text as="h2" textStyle="title.lg">
				Leverage your budget.<br />
				Empower your team.
			</Text>
			<Button size="lg">
				<Text gradient="green">Request Beta Access</Text>
			</Button>
		</TitleGroup>
	</BetaCTA>
  </Layout>
);

export const pageQuery = graphql`
	query EnterprisePage {
		creatorImage: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"app"}, name:{eq:"creator_teams"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
		review: file(sourceInstanceName:{eq:"images"}, relativeDirectory: {eq: "app"}, name:{eq:"review"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
		comingSoon: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"coming_soon"}, name:{eq:"coming_soon_teams"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
		stackedPreviews:allFile(filter:{
				sourceInstanceName:{
					eq:"images"
			},
			relativeDirectory:{
					eq:"stacked_previews"
			}
		}) {
			nodes {
				id
				childImageSharp  {
					gatsbyImageData(quality: 50, formats: WEBP)
				}
			}
		}
	}
`;
export default EnterprisePage
